<template>
  <div class="page-content">
    <!--error start-->

    <section
      class="fullscreen-banner p-0 o-hidden text-center white-overlay error-page"
      data-bg-img="images/bg/06.jpg"
      data-overlay="9"
    >
      <div class="align-center">
        <div class="container">
          <div class="row">
            <div class="col-md-12 text-center text-black">
              <h1>
                4<span><i>0</i></span
                >4
              </h1>
              <h5 class="mb-4 text-capitalize mt-3">
                Ooops, the page doesn't seem to exit.
              </h5>
              <a class="btn btn-theme btn-radius btn-iconic" href="index.html"
                ><i class="fas fa-long-arrow-alt-left"></i>
                <span>back to home</span></a
              >
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--error end-->
  </div>
</template>

<script>
export default {};
</script>
